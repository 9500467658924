<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Puntos de Gestión</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/Admin">Administrativo</router-link>
                  </li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">Puntos de gestión</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal-form-punto-gestion-export"
                        v-if="$store.getters.can('admin.puntosGestion.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <router-link to="/Admin/PuntosGestionCreate">
                        <button
                          type="button"
                          class="btn btn-primary"
                          @click="newItem = true"
                          v-if="
                            $store.getters.can('admin.puntosGestion.create')
                          "
                        >
                          <i class="fas fa-plus"></i>
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th>
                        Nombre
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="nombre"
                          @keyup="filtrarTabla()"
                        />
                      </th>
                      <th>
                        Ciudad
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="ciudad"
                          label="nombre"
                          :options="ciudades"
                          @input="filtrarTabla()"
                        >
                        </v-select>
                      </th>
                      <th class="text-center">Dirección</th>
                      <th class="text-center">Teléfono</th>
                      <th>
                        Estado
                        <select
                          v-model="estado"
                          @change="filtrarTabla()"
                          class="form-control form-control-sm"
                        >
                          <option value="null"></option>
                          <option value="1">Activo</option>
                          <option value="2">Inactivo</option>
                        </select>
                      </th>
                      <th class="text-center">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(row, index) in filteredRows"
                      :key="`id-${index}`"
                    >
                      <td v-html="highlightMatches(row.nombre)"></td>
                      <td v-html="highlightMatches(row.ciudad.nombre)"></td>
                      <td v-html="highlightMatches(row.direccion)"></td>
                      <td v-html="highlightMatches(row.telefono)"></td>
                      <td class="text-center">
                        <span
                          class="badge"
                          v-bind:class="[
                            row.estado == 'Activo'
                              ? 'badge-success'
                              : 'badge-danger',
                          ]"
                        >
                          {{ row.estado }}
                        </span>
                      </td>
                      <td>
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            v-if="
                              $store.getters.can('admin.puntosGestion.show')
                            "
                            @click="editPuntoGestion(row.id)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            :class="
                              row.estado == 'Activo'
                                ? 'btn btn-sm btn-warning'
                                : 'btn btn-sm btn-success'
                            "
                            data-toggle="modal"
                            data-target="#modal-form-punto_gestion"
                            v-if="
                              $store.getters.can('admin.puntosGestion.show')
                            "
                            @click="datosModal(row)"
                          >
                            <i
                              :class="
                                row.estado == 'Activo'
                                  ? 'fas fa-lock'
                                  : 'fas fa-unlock'
                              "
                            ></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-if="
                              $store.getters.can('admin.puntosGestion.delete')
                            "
                            @click="delPuntoGestion(row.id)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="puntosGestion.total">
                  <p>
                    Mostrando del <b>{{ puntosGestion.from }}</b> al
                    <b>{{ puntosGestion.to }}</b> de un total:
                    <b>{{ puntosGestion.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="puntosGestion"
                  @pagination-change-page="filtrarTabla"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
        <!-- Modal -->
        <div class="modal fade" ref="modalBloque" id="modal-form-punto_gestion">
          <div class="modal-dialog modal-md">
            <div class="modal-content">
              <div class="modal-header bg-frontera-top-left pt-2 pb-2">
                <h4 class="modal-title text-white">
                  Cambiar estado - {{ modal.title }}
                </h4>
                <button
                  type="button"
                  class="close text-white"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="close-modal"
                  ref="closeModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="form-group">
                  <label for="estado">Estado</label>
                  <select
                    id="estado"
                    class="form-control form-control-sm"
                    v-model="form.estado"
                    :class="$v.form.estado.$invalid ? 'is-invalid' : 'is-valid'"
                  >
                    <option value="" disabled>Seleccione...</option>
                    <option value="1">Activo</option>
                    <option value="2">Inactivo</option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Justificación</label>
                  <textarea
                    cols="3"
                    class="form-control form-control-sm"
                    v-model="form.justificacion_inactivacion"
                    :class="
                      form.estado == 2
                        ? $v.form.justificacion_inactivacion.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                        : ''
                    "
                  ></textarea>
                </div>
              </div>
              <div class="modal-footer justify-content-between">
                <button
                  type="button"
                  class="btn btn-primary"
                  v-show="!$v.form.$invalid"
                  @click="save()"
                >
                  Guardar
                </button>
              </div>
            </div>
            <!-- /.modal-content -->
          </div>
          <!-- /.modal-dialog -->
        </div>
      </div>
    </div>
    <PuntosGestionExport ref="PuntosGestionExport" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import pagination from "laravel-vue-pagination";
import PuntosGestionExport from "./PuntosGestionExport";

export default {
  name: "PuntosIndex",
  components: {
    vSelect,
    pagination,
    PuntosGestionExport,
  },
  data() {
    return {
      puntosGestionTabla: [],
      nombre: "",
      ciudad: "",
      estado: null,
      filter: "",
      filtros: {},
      form: {
        id: null,
        estado: null,
        justificacion_inactivacion: null,
      },
      modal: {
        title: "",
      },
    };
  },
  validations() {
    if (this.form.estado == 1) {
      return {
        form: {
          estado: {
            required,
          },
        },
      };
    } else {
      return {
        form: {
          estado: {
            required,
          },
          justificacion_inactivacion: {
            required,
          },
        },
      };
    }
  },
  beforeMount() {
    (this.filtros = {
      nombre: null,
      ciudad_id: null,
      estado: null,
    }),
      this.actGetPuntosGestion(this.filtros).then(() => {
        this.puntosGestionTabla = this.puntosGestion.data;
      });
    this.actGetCiudades();
  },
  computed: {
    ...mapState("modOperacion", ["puntosGestion", "ciudades"]),

    filteredRows() {
      return this.puntosGestionTabla.filter((row) => {
        const nombre = row.nombre.toString().toLowerCase();
        const ciudad = row.ciudad.nombre.toLowerCase();
        const direccion = row.direccion ? row.direccion.toLowerCase() : "";
        const telefono = row.telefono ? row.telefono : ""; //.toLowerCase();
        const estado = row.estado.toLowerCase();
        const searchTerm = this.filter.toLowerCase();

        return (
          nombre.includes(searchTerm) ||
          ciudad.includes(searchTerm) ||
          direccion.includes(searchTerm) ||
          telefono.includes(searchTerm) ||
          estado.includes(searchTerm)
        );
      });
    },
  },
  methods: {
    ...mapActions("modOperacion", [
      "actGetPuntosGestion",
      "actDelPuntoGestion",
      "actGetCiudades",
      "actUpdtPuntoGestion",
    ]),

    highlightMatches(texto) {
      var text = String(texto);
      const matchExists = text
        .toLowerCase()
        .includes(this.filter.toLowerCase());
      if (!matchExists) return text;

      const re = new RegExp(this.filter, "ig");
      return text.replace(
        re,
        (matchedText) => `<strong>${matchedText}</strong>`
      );
    },

    filtrarTabla(page = 1) {
      var data = [];
      (this.filtros = {
        nombre: this.nombre ? this.nombre : null,
        ciudad_id: this.ciudad ? this.ciudad.id : null,
        estado: this.estado,
      }),
        (data[0] = page);
      data[1] = this.filtros;
      //alert(JSON.stringify(this.filtros));
      this.puntosGestionTabla = [];
      this.actGetPuntosGestion(data).then(() => {
        this.puntosGestionTabla = this.puntosGestion.data;
      });
    },

    editPuntoGestion(id) {
      this.$router.push({
        name: "/Admin/PuntosGestionEdit",
        params: { id: id },
      });
    },

    delPuntoGestion(id) {
      this.$swal({
        title: "Esta seguro de eliminar este Item?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          this.actDelPuntoGestion(id).then(() => {
            this.actGetPuntosGestion();
            this.$swal({
              icon: "success",
              title: "Se elimino el item exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
            });
          });
        }
      });
    },
    datosModal(punto_gestion) {
      this.modal.title = punto_gestion.nombre;
      this.form.id = punto_gestion.id;
      if (punto_gestion.estado == "Activo") {
        this.form.estado = 1;
      } else {
        this.form.estado = 2;
      }
      this.form.justificacion_inactivacion =
        punto_gestion.justificacion_inactivacion;
    },
    save() {
      if (!this.$v.form.$invalid) {
        let data = [];
        data[0] = this.form.id;
        data[1] = {
          estado: this.form.estado,
          justificacion_inactivacion: this.form.justificacion_inactivacion,
        };
        this.actUpdtPuntoGestion(data)
          .then(() => {
            this.cargando = false;
            this.$refs.closeModal.click();
            this.$swal({
              icon: "success",
              title: "El punto de gestión se actualizó con éxito",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.actGetPuntosGestion(this.filtros).then(() => {
              this.puntosGestionTabla = this.puntosGestion.data;
            });
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          });
      }
    },
  },
};
</script>

<style lang="scss">
[v-cloak] {
  display: none;
}
.edit {
  display: none;
}
.editing .edit {
  display: block;
}
.editing .view {
  display: none;
}
</style>
